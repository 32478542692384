
import Vue from "vue";

export default Vue.extend({
  name: "ConcludeProgramModal",
  props: {
    mentor: {
      type: Object
    },
    mentee: {
      type: Object
    }
  },
  data() {
    return {
      btnLoading: false
    };
  },
  methods: {
    onSubmit() {
      this.btnLoading = true;

      this.$store
        .dispatch("program/CONCLUDE_PROGRAM", this.$route.params.id)
        .then(() => {
          this.btnLoading = false;
          (this as any).$alertify.success(
            `Program has been concluded successfully`
          );
          this.$bvModal.hide("conclude-program");
          this.$emit("refresh");
        })
        .catch(error => {
          this.btnLoading = false;
          if (error.response && error.response.status === 422) {
            (this as any).$alertify.error(error.response.data.message);
            this.$bvModal.show("cannot-conclude-program");
            this.$bvModal.hide("conclude-program");
          }
        });
    }
  }
});
